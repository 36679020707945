/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";
@import '~@ng-select/ng-select/themes/default.theme.css';

// TODO CCC: figure out better way to do these styles
// @import '~@ng-select/ng-select/themes/default.theme.css';

// TODO CCC: figure out better way to do these styles
@orange: #f48221;
@green: #b4cd34;
@darkGreen: #718800;
@darkOrange: #744044;

@import '@mt-ng2/styles/styles.less';
// @import '@mt-ng2/styles/tables.less';
@primary: #21473c;
@secondary: @orange;
@textcolor: rgb(0, 0, 0);
@errorcolor: #dd4b39;
@navPrimary: #000000;
@tableHeadingColor: @darkOrange;
@tableStripedAltBackgroundColor: fade(@green, 25%);

html {
    font-size: 16px;
}

.small-text {
    font-size: 14px;
}

.no-wrap {
    white-space: nowrap !important;
}

.btn.btn-primary.btn-fab-lg {
    background-color: @orange;
    border: solid 1px @darkOrange;
}

.btn.btn-orange {
    background-color: fade(@orange, 50%);
    border: solid 1px @darkOrange;
}

.btn.btn-default.btn-fab-md.btn-fab-center {
    background-color: fade(@orange, 75%);
    color: white;
    border: solid 1px @darkOrange;
}

.small-btn-float-right {
    background-color: @orange;
    border: solid 1px @darkOrange;
    border-radius: 2px;
    color: white;
    width: 10px;
    height: 10px;
    position: relative;
    float: right;
    bottom: 10px;
}

h2 {
    color: @darkOrange;
}

hr {
    background-color: @green;
}

.nav-item a {
    color: @orange !important;
}

a#zentail.nav-link {
    border-radius: 4px 4px 4px 4px;
    position: relative;
    margin-left: 10px;
}

app-nav-menu-item .fa {
    color: @green !important;
}

.orange {
    color: @orange;
}

.sidebar-menu > li.header,
.skin-blue .sidebar-menu > li.header {
    color: @orange;
}

.miles-form h4,
.miles-card h4,
.heading-highlight {
    color: @orange;
    border-bottom: solid 1px @green;
}

.btn-transparent:hover {
    background-color: fade(@orange, 25%);
    border: solid 1px @darkOrange;
    color: @darkOrange;
}

.tag {
    background-color: @orange !important;
}

.fa-sort-asc {
    color: @orange;
}

.fa-sort-desc {
    color: @green;
}

.mtFilterLabel {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-image: none;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn-success {
    background-color: fade(@green, 50%);
    color: @primary;
}

.btn-success:active:focus,
.btn-success:hover {
    background-color: @green;
    color: @primary;
}

table thead {
    background-color: @primary;
    color: white;
}

tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

tr.parentActionRow {
    background-color: @tableStripedAltBackgroundColor !important;
}

tr.counterActionRow {
    background-color: white !important;
}

tr.parentActionRow > td {
    background-color: @tableStripedAltBackgroundColor !important;
}

tr.counterActionRow > td {
    background-color: white !important;
}

.app-root-wrapper {
    background-color: #ecf0f5;
    min-height: 100vh;
}

.right {
    float: right;
}

span textarea {
    resize: vertical;
}

.nav-link {
    border: solid 1px orange !important;
}

nav > li > a:focus:not(#zentail):not(#sendPo):not(#itemPoTab):not(#sendWeightDim),
.nav > li > a:hover:not(#zentail):not(#sendPo):not(#itemPoTab):not(#sendWeightDim),
.nav > li > a:focus:not(#zentail):not(#sendPo):not(#itemPoTab):not(#sendWeightDim),
.nav > li > a:hover,
.nav-link.active:not(#zentail):not(#sendPo):not(#itemPoTab):not(#sendWeightDim) {
    background-color: @green;
    color: @primary !important;
}

.flex-row {
    display: flex;
    justify-content: space-between;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-nowrap {
    display: flex;
    flex-wrap: nowrap;
}

.centerWithMargins {
    margin-left: 50%;
    margin-right: 50%;
}

.fullWidth {
    width: 100%;
}

.indent {
    text-align: center;
}

a.dropdown-toggle.user-dropdown-toggle:hover {
    color: white !important;
}

.miles-card th b {
    color: white;
}

.miles-editable {
    height: 100% !important;
}


// custom column classes

.description-column-content {
    display: block;
    width: 200px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.custom-slider {
    .ngx-slider {
        .ngx-slider-selection {
            background: @orange !important;
        }
    }
}

.custom-slider {
    .ngx-slider {
        .ngx-slider-pointer {
            background: @green !important;
            color: white;
        }
    }
}

.custom-slider {
    .ngx-slider {
        .ngx-slider-pointer::after {
            background-color: white !important;
        }
    }
}

.custom-slider {
    .ngx-slider {
        .ngx-slider-bubble {
            font-size: 14px;
            color: @textcolor !important;
        }
    }
}

.centerText {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.vl {
    border-left: 1px solid @textcolor;
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
}

.vlJustBorder {
    border-left: 1px solid @textcolor;
}

.cursor {
    cursor: pointer;
}

.ng-dropdown-panel {
    background-color: white !important;
}

custom-managed-list br {
    display: none;
}

.checkGreen {
    color: @darkGreen;
}

.checkRed {
    color: red;
}

app-custom-nav-menu-item a {
    color: white;
}

app-custom-nav-menu-item a:hover {
    color: white;
}

.wide-button {
    width: 200px;
}

.Expected-Payment-Row {
    color: grey;
}

.miles-card {
    margin-bottom: 1rem;
}

.menu-title {
    padding-left: 1em;
    cursor: pointer;
    padding-right: 20px;
    width: 195px;
    display: block;
    color: white;
}

.orderReportRowSeparator {
    border-bottom: solid black;
}

.export-button {
    display: none;
}

.print-button {
    display: none;
}

.btn-big-width {
    width: 200px;
}

.btn-sm-width {
    width: 130px;
}

.btn-x-sm-width {
    width: 50px;
}

.smallEntityCol {
    width: 50px;
    overflow-wrap: break-word;
    display: inline-block;
}

body > .swal2-container .swal2-popup {
    font-size: 1rem;
    width: auto;
}

.swal2-content {
    font-size: 1rem !important;
}

.highlightOrange {
    background-color: @orange;
}

.importantPeach {
    background-color: rgb(255, 196, 145) !important;
    color: black;
}

table.table-striped tr.inventoryHighlightRow > td {
    background-color: rgb(243, 207, 198) ;
}

table.table-striped tr.disabledRow > td {
    background-color: rgb(175, 175, 175);
    cursor: not-allowed;
}

.table-cell-border {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}

/** Modal Table Classes **/
.buyBoxWrapper {
    width: 280px;
    height: 300px;
    margin-right: auto;
    margin-left: auto;
}

.dailyBreakdownWrapper {
    width: 180px;
    height: 300px;
    margin-right: auto;
    margin-left: auto;
}

.labelWidth {
    margin-top: auto;
    margin-bottom: auto;
    width: 130px;
    text-align: right;
    float: left;
}

.buyBoxItem {
    height: 35px;
}

.buyBoxInput {
    width: 80px;
    height: 25px;
    text-align: right;
}

.buyBoxNonInput {
    padding-right: 12px;
}

app-storage-fees-table br {
    display: none;
}

body {
    overflow-x: visible;
    overflow-y: visible;
}

.wrapper {
    overflow-x: visible;
    overflow-y: visible;
}

.table-responsive {
    overflow-x: visible;
}

.table-x-scroll {
    overflow-x: auto;
}

.search-controls-bottom {
    position: fixed;
    bottom: 0px;
    background-color: #ecf0f5;
    animation-duration: 0.5s;
    animation-name: slidein;
}

.virtual thead {
    background-color: #21473c !important;
    color: white !important;
}

app-returns-scanner-step-5 .btn-group {
    display: flex !important;
    flex-direction: column !important;
}

@keyframes slidein {
    from {
        bottom: -50%;
    }

    to {
        bottom: 0px;
    }
}

//Returns Scanner Styles
.scanner-width {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align:center;
}
app-returns-scanner h2, app-return-shipments-scanner h2, .reports-scanner h2 {
    font-size: 30px !important;
}
app-returns-scanner h4, app-return-shipments-scanner h4, .reports-scanner h4 {
    font-size: 24px !important;
}
app-returns-scanner label, app-return-shipments-scanner label, .reports-scanner label {
    font-size: 20px !important;
}
app-returns-scanner input.form-control, app-return-shipments-scanner input.form-control {
    margin-top: 20px;
    margin-bottom: 50px;
    height: 70px !important;
    font-size: 20px !important;
    width: 50% !important;
    margin-left: auto;
    margin-right: auto;
}
app-returns-scanner button, app-return-shipments-scanner button, .reports-scanner button {
    height: 100px;
    font-size: 20px !important;
}
app-returns-scanner .display-padded {
    font-size: 20px !important;
}

.multiSelectStyle.reports-scanner {
    width: 50% !important;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
}

.reports-scanner {
    font-size: 20px !important;
}

.reports-scanner input.form-control {
    height: 70px !important;
    font-size: 20px !important;
}
.swal2-shown .dropdown-menu {
    font-size: 20px !important;
}
// Datatable Adjustments //
datatable-body-row {
    height: 50px !important;
}

datatable-body-cell {
    height: 50px !important;
}

/** Bootstrap 4 classes **/

.m-0 {
    margin: 0 !important;
}
.m-1 {
    margin: 0.25rem !important;
}
.m-2 {
    margin: 0.5rem !important;
}
.m-3 {
    margin: 1rem !important;
}
.m-4 {
    margin: 1.5rem !important;
}
.m-5 {
    margin: 3rem !important;
}

.mt-0 {
    margin-top: 0 !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}
.mr-1 {
    margin-right: 0.25rem !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}
.mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}
.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.ml-2 {
    margin-left: 0.5rem !important;
}
.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}
.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}
.mr-3 {
    margin-right: 1rem !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.ml-3 {
    margin-left: 1rem !important;
}
.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}
.my-3 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}
.mr-4 {
    margin-right: 1.5rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.ml-4 {
    margin-left: 1.5rem !important;
}
.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}
.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}
.mr-5 {
    margin-right: 3rem !important;
}
.mb-5 {
    margin-bottom: 3rem !important;
}
.ml-5 {
    margin-left: 3rem !important;
}
.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}
.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}
.mr-auto {
    margin-right: auto !important;
}
.mb-auto {
    margin-bottom: auto !important;
}
.ml-auto {
    margin-left: auto !important;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.my-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
}

.p-0 {
    padding: 0 !important;
}
.p-1 {
    padding: 0.25rem !important;
}
.p-2 {
    padding: 0.5rem !important;
}
.p-3 {
    padding: 1rem !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.p-5 {
    padding: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pl-0 {
    padding-left: 0 !important;
}
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}
.pr-1 {
    padding-right: 0.25rem !important;
}
.pb-1 {
    padding-bottom: 0.25rem !important;
}
.pl-1 {
    padding-left: 0.25rem !important;
}
.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}
.pr-2 {
    padding-right: 0.5rem !important;
}
.pb-2 {
    padding-bottom: 0.5rem !important;
}
.pl-2 {
    padding-left: 0.5rem !important;
}
.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}
.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}
.pr-3 {
    padding-right: 1rem !important;
}
.pb-3 {
    padding-bottom: 1rem !important;
}
.pl-3 {
    padding-left: 1rem !important;
}
.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}
.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}
.pr-4 {
    padding-right: 1.5rem !important;
}
.pb-4 {
    padding-bottom: 1.5rem !important;
}
.pl-4 {
    padding-left: 1.5rem !important;
}
.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}
.pr-5 {
    padding-right: 3rem !important;
}
.pb-5 {
    padding-bottom: 3rem !important;
}
.pl-5 {
    padding-left: 3rem !important;
}
.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}
.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.min-three-digits {
    min-width: 6em;
    max-width: 10em;
}

// align table data with headers in modal
#swal2-html-container > swal-portal > div > entity-list > div > table > tbody > tr > td {
    text-align: left;
}

/* making all parent overflows visible, so that sticky positioning will work for virtual-scroll-entity-list */

body {
    overflow-x: visible;
    overflow-y: visible;
}

.wrapper {
    overflow-x: visible;
    overflow-y: visible;
}

.table-responsive {
    overflow-x: visible;
}

app-inventory-report > div mt-entity-list > div.table-responsive > table >thead {
    position: sticky;
    top: 50px;
}

.popup-backdrop {
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 50;
}

.popup {
    position: fixed; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    text-align: center; 
    padding: 15px 25px 25px 25px; 
    border-radius: 10px;
    max-height: 80vh;
    overflow: auto;
}

.popup-close-btn {
    position: sticky; 
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    top: 0px; 
    right: 0px;
    font-size: 20px; 
    cursor: pointer;
}

.infinite-loading-spinner {
    width: 32px;
    height: 32px;
    border: 5px solid #555;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

/** Utility Classes **/

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
}

.gap-sm {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-7 {
    margin-bottom: 7px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mx-2 {
    margin-left: 2px;
    margin-right: 2px;
}

.text-red {
    color: red;
}

.text-primary {
    color: @primary;
}

.font-bold {
    font-weight: bold;
}

.cursor-pointer {
    cursor: pointer;
}

.min-h-half-screen {
    min-height: 50vh !important;
}

.hidden {
    display: none;
}

.block {
    display: block;
}

.h-0 {
    height: 0;
}

.h-fit {
    height: fit-content;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.overflow-hidden {
    overflow: hidden;
}

.border {
    border: 1px solid #ccc;
}

.rounded-sm {
    border-radius: 5px;
}

.w-fit {
    width: fit-content;
}

.w-full {
    width: 100%;
}

.w-auto {
    width: auto;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.text-sm {
    font-size: 14px;
}

.min-w-200 {
    min-width: 200px;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.max-h-3\/4-screen {
    max-height: 75vh;
}

.h-1\/3-screen {
    height: 33vh;
}

.bg-gray {
    background-color: #f9f9f9;
}

.inline-block {
    display: inline-block;
}

.underline {
    text-decoration: underline;
}

.text-gray {
    color: gray;
}

/** *************** **/